<script setup>
useHead({
  viewport: "width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no",
});
</script>
<template>
  <div class="app-content">
    <div class="app-page">
      <NuxtPage></NuxtPage>
    </div>
  </div>
</template>

<style scoped>
.app-content {
  /* position: relative; */
  max-width: 500px;
  height: 100%;
  margin: auto;
}
.app-page {
  /* padding-top: 70px; */
  height: 100%;
}
</style>
